const isSquareSrc = (imgSrc) => {
  return imgSrc?.includes?.('_square')
}

export const containerRatio = (fixedRatio, imgSrc) => {
  if (fixedRatio === '3-4') return 4 / 3
  
  if (fixedRatio === '4-5') return 5 / 4

  if (fixedRatio === '5-6') return 6 / 5
  

  if (fixedRatio === '13-16') return 16 / 13

  if (fixedRatio === '14-17') return 17 / 14

  if (fixedRatio === '1-1') return 1 / 1
  // 特殊宽高比
  if (fixedRatio) {
    let [width, height] = fixedRatio.split('-')
    width = Number(width)
    height = Number(height)
    if (width && height) {
      return height / width
    }
  }

  // 以下是对非固定容器宽高比 根据imgSrc地址确定的容器大小
  if (isSquareSrc(imgSrc)) return 1 / 1

  // 3 : 4
  return 4 / 3
}
